import React, { useState, useEffect, useMemo, useRef } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause, Music } from 'lucide-react';

const DiscoBall = ({ isPlaying }) => (
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '60px',
    height: '60px',
    transition: 'opacity 0.3s',
    opacity: isPlaying ? 1 : 0,
  }}>
    <div style={{
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      background: 'radial-gradient(circle, white, #888)',
      boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de',
      animation: isPlaying ? 'spin 5s linear infinite' : 'none',
    }}>
      {[...Array(5)].map((_, i) => (
        <div key={i} style={{
          position: 'absolute',
          width: '100%',
          height: '2px',
          background: 'rgba(255,255,255,0.7)',
          top: `${i * 20}%`,
          transform: `rotate(${i * 36}deg)`,
          animation: isPlaying ? `reflect 2s ease-in-out ${i * 0.1}s infinite` : 'none',
        }} />
      ))}
    </div>
  </div>
);

const RoomArranger = () => {
  const generateColors = (n) => {
    return Array.from({ length: n }, (_, i) => 
      `hsl(${(i * 360 / n) % 360}, 100%, 50%)`
    );
  };

  const generateSubsetPermutations = (colors, count) => {
    const permutations = [];
    for (let i = 0; i < count; i++) {
      permutations.push(colors.slice().sort(() => Math.random() - 0.5));
    }
    return permutations;
  };

  const factorial = (n) => {
    let result = 1;
    for (let i = 2; i <= n; i++) result *= i;
    return result;
  };

  const [numRooms, setNumRooms] = useState(11);
  const [colors, setColors] = useState([]);
  const [page, setPage] = useState(0);
  const [usePurple, setUsePurple] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioError, setAudioError] = useState(null);
  const audioRef = useRef(null);
  const perPage = 100;

  const accentColor = usePurple ? 'purple' : 'magenta';

  const permutations = useMemo(() => {
    const maxPermutations = 100000;
    return generateSubsetPermutations(colors, Math.min(maxPermutations, factorial(numRooms)));
  }, [colors, numRooms]);

  const totalPages = Math.ceil(permutations.length / perPage);

  useEffect(() => {
    setColors(generateColors(numRooms));
    setPage(0);
  }, [numRooms]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      let direction = 1;
      let count = 0;
      interval = setInterval(() => {
        setPage((prevPage) => (prevPage + 1) % totalPages);
        setUsePurple((prev) => !prev);
        setNumRooms((prev) => {
          if (count % 2 === 0) {
            const newValue = prev + (2 * direction);
            if (newValue > 25 || newValue < 1) {
              direction *= -1;
              return prev + (2 * direction);
            }
            return newValue;
          }
          count++;
          return prev;
        });
      }, 500);
    }
    return () => clearInterval(interval);
  }, [isPlaying, totalPages]);

  const handleNumRoomsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > 0 && value <= 25) {
      setNumRooms(value);
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      audioRef.current.play().catch(e => {
        console.error("Error playing audio:", e);
        setAudioError("Failed to play audio. Please check your audio file.");
      });
    } else {
      audioRef.current.pause();
    }
  };

  const handleAudioError = (e) => {
    console.error("Audio error:", e);
    setAudioError("Error loading audio. Please check your audio file.");
  };

  const gridCols = Math.ceil(Math.sqrt(numRooms));

  return (
    <div style={{ backgroundColor: 'black', color: accentColor, fontFamily: 'Arial, sans-serif', padding: '20px', position: 'relative' }}>
      <audio 
        ref={audioRef} 
        loop 
        onError={handleAudioError}
      >
        <source src={`${process.env.PUBLIC_URL}/yummy-240482.mp3`} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      
      <DiscoBall isPlaying={isPlaying} />
      
      {audioError && <div style={{ color: 'red', marginBottom: '10px' }}>{audioError}</div>}
      
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Room Arrangement Visualizer</h1>
      
      <div style={{ marginBottom: '20px' }}>
        <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Room Arranger</h2>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="numRooms">Number of Rooms (1-25): </label>
          <input
            id="numRooms"
            type="number"
            min="1"
            max="25"
            value={numRooms}
            onChange={handleNumRoomsChange}
            style={{ backgroundColor: 'black', color: accentColor, border: `1px solid ${accentColor}`, padding: '5px' }}
          />
        </div>
        <div>
          <p>Total Possible: {factorial(numRooms).toLocaleString()}</p>
          <p>Displayed: {permutations.length.toLocaleString()}</p>
          <p>Page: {page + 1} / {totalPages}</p>
        </div>
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <button 
            onClick={() => setPage(p => Math.max(0, p - 1))} 
            disabled={page === 0 || isPlaying}
            style={{ backgroundColor: accentColor, color: 'black', padding: '5px 10px', border: 'none', cursor: 'pointer' }}
          >
            Prev
          </button>
          <button
            onClick={togglePlay}
            style={{ backgroundColor: accentColor, color: 'black', padding: '5px 10px', border: 'none', cursor: 'pointer' }}
          >
            {isPlaying ? 'Stop Disco' : 'Start Disco'}
          </button>
          <button 
            onClick={() => setPage(p => Math.min(totalPages - 1, p + 1))} 
            disabled={page === totalPages - 1 || isPlaying}
            style={{ backgroundColor: accentColor, color: 'black', padding: '5px 10px', border: 'none', cursor: 'pointer' }}
          >
            Next
          </button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <label>
            <input
              type="checkbox"
              checked={usePurple}
              onChange={() => setUsePurple(!usePurple)}
              style={{ marginRight: '5px' }}
            />
            Use Dark Purple Theme
          </label>
        </div>
      </div>

      <div>
        <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Arrangements Sample</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: '10px' }}>
          {permutations.slice(page * perPage, (page + 1) * perPage).map((permutation, index) => (
            <div key={index} style={{ border: `1px solid ${accentColor}`, padding: '5px' }}>
              <div style={{ marginBottom: '5px', fontSize: '12px' }}>Arrangement {page * perPage + index + 1}</div>
              <div 
                style={{ 
                  display: 'grid',
                  gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
                  gap: '2px',
                  aspectRatio: '1'
                }}
              >
                {permutation.map((color, colorIndex) => (
                  <div key={colorIndex} style={{ backgroundColor: color, aspectRatio: '1' }} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomArranger;