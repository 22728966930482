import React from 'react';
import RoomArranger from './components/RoomArranger';

function App() {
  return (
    <div className="App">
      <h1>Room Arrangement Visualizer</h1>
      <RoomArranger />
    </div>
  );
}

export default App;